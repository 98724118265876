import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/views/index/home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: home,
  },
  {
    path: "/protocol-service",

    component: () =>
      import(
        /* webpackChunkName: "protocol-service" */ "@/views/agreement/Service.vue"
      ),
  },
  {
    path: "/protocol-private",
    name: "隐私政策",
    component: () =>
      import(
        /* webpackChunkName: "protocol-private" */ "@/views/agreement/Private.vue"
      ),
  },
  // {
  //   path: "/home",

  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "@views/index/home.vue"),
  // },
  {
    path: "/invite",

    component: () =>
      import(
        /* webpackChunkName: "invite" */ "@/views/InvitationCode/invite.vue"
      ),
  },
  {
    path: "/qrcode",

    component: () =>
      import(
        /* webpackChunkName: "invite" */ "@/views/InvitationCode/paymentPage.vue"
      ),
  },
  {
    path: "/qrcode2",

    component: () =>
      import(
        /* webpackChunkName: "qrcode2" */ "@/views/InvitationCode/paymentPagePhone.vue"
      ),
  },
  {
    path: "/downloadapp",

    component: () =>
      import(
        /* webpackChunkName: "downloadapp" */ "@/views/InvitationCode/downloadapp.vue"
      ),
  },

  {
    path: "/protocol-service-en",

    component: () =>
      import(
        /* webpackChunkName: "protocol-service-en" */ "@/views/agreement/Service-en.vue"
      ),
  },
  {
    path: "/protocol-private-en",

    component: () =>
      import(
        /* webpackChunkName: "protocol-private-en" */ "@/views/agreement/Private-en.vue"
      ),
  },
  {
    path: "/home-en",

    component: () =>
      import(/* webpackChunkName: "home-en" */ "@/views/index/home-en.vue"),
  },
  {
    path: "/invite-en",

    component: () =>
      import(
        /* webpackChunkName: "invite-en" */ "@/views/InvitationCode/invite-en.vue"
      ),
  },
  {
    path: "/qrcode-en",

    component: () =>
      import(
        /* webpackChunkName: "qrcode-en" */ "@/views/InvitationCode/paymentPage-en.vue"
      ),
  },
  {
    path: "/qrcode2-en",

    component: () =>
      import(
        /* webpackChunkName: "qrcode2-en" */ "@/views/InvitationCode/paymentPagePhone-en.vue"
      ),
  },
  {
    path: "/downloadapp-en",

    component: () =>
      import(
        /* webpackChunkName: "downloadapp-en" */ "@/views/InvitationCode/downloadapp-en.vue"
      ),
  },
  {
    path: "/faq",

    component: () =>
      import(/* webpackChunkName: "faq" */ "@/views/faq/faq.vue"),
  },
  {
    path: "/faq-en",

    component: () =>
      import(/* webpackChunkName: "faq-en" */ "@/views/faq/faq-en.vue"),
  },
  {
    path: "/kefu",

    component: () =>
      import(/* webpackChunkName: "kefu" */ "@/views/kefu/kefu.vue"),
  },
  {
    path: "/kefu-en",

    component: () =>
      import(/* webpackChunkName: "kefu-en" */ "@/views/kefu/kefu-en.vue"),
  },
  {
    path: "/hot",

    component: () =>
      import(/* webpackChunkName: "hot" */ "@/views/hot/hot.vue"),
  },
  {
    path: "/hot-en",

    component: () =>
      import(/* webpackChunkName: "hot-en" */ "@/views/hot/hot-en.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
